function showInfoMsg(msg, that) {
    // console.log(msg, that)
    that.$notify({
        title: "",
        message: msg,
        type: "success",
        dangerouslyUseHTMLString: true,
        duration: 3000,
    });
}
function showErrorMsg(msg, that) {
    // console.log(msg, that)
    that.$notify({
        title: "",
        message: msg,
        type: "error",
        dangerouslyUseHTMLString: true,
        duration: 0,
    })
}

function confirmMsg(tipContent, msg, that) {
    that.$confirm(tipContent, window.vm.$i18n.t("materialAdmin.tip"), {
        confirmButtonText: window.vm.$i18n.t("matermaterialAdminialConfig.confirm"),
        cancelButtonText: window.vm.$i18n.t("matermaterialAdminialConfig.cancel"),
        type: 'warning'
    }).then(() => {
        that.$message({
            type: 'success',
            message: msg
        });
    })
}
export default { showInfoMsg, showErrorMsg, confirmMsg }