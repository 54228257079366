import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

let kejianrouter = new Router({
  routes: [{
      path: '/',
      name: 'HomeComponent',
      component: () => import('./views/Home')
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: () => import('./views/privacy/Privacy.vue')
    },
    {
      path: '/user-center',
      name: 'user-center',
      component: () => import('./views/privacy/UserCenter.vue')
    },
    {
      path: '/pay-agreement',
      name: 'pay-agreement',
      component: () => import('./views/privacy/PayAgreement.vue')
    }
  ]
})

// 判断是否需要登录权限 以及是否登录
kejianrouter.beforeEach((to, from, next) => {
  // 判断是否需要登录权限
  if (to.matched.some(res => res.meta.requireAuth)) {
    // 判断是否登录
    // if (sessionStorage.getItem('token')) {
    //   next()
    // } else {
    //   // 没登录则跳转到登录界面
    //   next({
    //     path: '/login',
    //     query: {
    //       redirect: to.fullPath
    //     }
    //   })
    // }
    next()
  } else {
    next()
  }
})

export default kejianrouter
